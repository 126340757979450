.about-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 0 !important;
  margin: 0 75px 0 75px !important;
}

.about-left {
  display: flex;
  /* justify-content:center; */
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 300px;
}

.about-right {
  width: 750px;
}

.about-left h3,
.about-left h4 {
  cursor: default;
}

.about-left h3 {
  font-family: "Archivo", sans-serif;
  font-weight: 800;
  font-size: 50px;
  margin: 0px;
}

.about-left h4 {
  font-family: "Manrope", sans-serif;
  font-size: 20px;
}

.about-right p {
  font-family: "Manrope", sans-serif;
  font-size: 17px;
  font-weight: 400;
  margin-bottom: 24px;
  cursor: default;
}

.about-right h4 {
  font-family: "Archivo", sans-serif;
  font-weight: 700;
  line-height: 12px;
  margin: 0;
  cursor: default;
}

.certificates-wrapper {
  /* height: 365px; */
  padding: 0px 12px 12px 12px;
  margin-top: 8px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.certificates-ratio img {
  width: 210px;
  border-radius: 0.25rem;
  cursor: pointer;
}

.dif-image img {
  border-radius: 0.25rem;
  cursor: pointer;
  margin-bottom: 8px;
}
@media (max-width: 1400px) {
  .about-wrapper {
    margin: 0 20px 0 20px !important;
  }
}

@media (max-width: 1200px) {
  .about-wrapper {
    margin: 0 20px 0 20px !important;
    flex-direction: column !important;
    justify-content: start;
  }

  .about-left {
    align-items: flex-start;
  }

  .about-right {
    width: 100%;
  }

  .certificates-wrapper {
    /* height: 255px; */
  }

  .certificates-ratio img {
    width: 260px;
  }
}

@media (max-width: 992px) {
  .certificates-ratio img {
    width: 200px;
  }

  .about-left h3 {
    font-size: 40px;
  }

  .about-left h4 {
    font-size: 16px;
  }

  .about-right p {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .certificates-ratio img {
    width: 140px;
  }
}

@media (max-width: 576px) {
  .col.certificates-ratio {
    text-align: center;
    padding: 0;
  }

  .certificates-ratio.gap-image img {
    margin-top: 1.5rem;
  }

  .certificates-ratio img {
    width: 300px;
  }
}
